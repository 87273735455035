<template>
  <TableLayout :permissions="['business:project:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="项目名称" prop="projectName">
        <el-input v-model="searchForm.projectName" placeholder="请输入项目名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="子账号" prop="queryContent">
        <el-input v-model="searchForm.queryContent" placeholder="请输入姓名/手机号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:projectpermisson:create']">
        <li><el-button type="primary" @click="$refs.operaSJChildrenUserWindow.open('新增关联', { type: '2' })" icon="el-icon-plus" v-permissions="['business:labels:create']">新增关联</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border
      >
        <el-table-column prop="projectName" label="项目名称" align="center" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="realname" label="关联用户" align="center" min-width="120px" how-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center" min-width="120px"></el-table-column>
        <el-table-column prop="editDate" label="关联时间" align="center" min-width="120px"></el-table-column>
        <el-table-column label="操作" align="center" min-width="60px">
          <template slot-scope="{row}">
            <el-button type="text" @click="deleteById(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <OperaSJChildrenUserWindow ref="operaSJChildrenUserWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaSJChildrenUserWindow from '@/components/business/user/OperaSJChildrenUserWindow'
export default {
  name: 'CompanyChange',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaSJChildrenUserWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        projectName: '',
        queryContent: ''
      }
    }
  },
  created () {
    this.config({
      module: '企业变更信息记录表',
      api: '/business/sjProjectDeclares',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    
  }
}
</script>
