
<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-form  ref="companyForm" v-if="roleList.findIndex(item => item.roleType == 0) != -1" :model="companyForm" label-width="180px" :rules="rules" label-suffix="：" inline>
      <div>
        <el-form-item label="企业评分" prop="score">
          <el-input
            v-model="companyForm.score"
            placeholder="请输入企业评分"
            @input="inputAction(companyForm, 'score')"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="企业评分附件" prop="multiFileDTOList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upCompanyFileScore.click()">上传附件</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="companyForm.multiFileDTOList"
              linkName="fileurlfull"
            />
          </div>
        </el-form-item>
        <input type="file" accept="image/*,.pdf" @change="upCompanyFileScore" ref="upCompanyFileScore" style="display: none;" />
      </div>
    </el-form>
    <el-form  ref="xqForm" :model="xqForm" v-if="roleList.findIndex(item => item.roleType == 1) != -1" label-width="180px" :rules="rules" label-suffix="：" inline>
      <div>
        <el-form-item label="县区评分" prop="score">
          <el-input
            v-model="xqForm.score"
            placeholder="请输入县区评分"
            @input="inputAction(xqForm, 'score')"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="县区评分附件" prop="multiFileDTOList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upXqFileScore.click()">上传附件</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="xqForm.multiFileDTOList"
              linkName="fileurlfull"
            />
          </div>
        </el-form-item>
        <input type="file" accept="image/*,.pdf" @change="upXqFileScore" ref="upXqFileScore" style="display: none;" />
      </div>
    </el-form>
    <el-form  ref="serviceForm" :model="serviceForm" v-if="roleList.findIndex(item => item.roleType == 2) != -1" label-width="180px" :rules="rules" label-suffix="：" inline>
      <div>
        <el-form-item label="服务机构评分" prop="score">
          <el-input
            v-model="serviceForm.score"
            placeholder="请输入县区评分"
            @input="inputAction(serviceForm, 'score')"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务机构评分附件" prop="multiFileDTOList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upServiceFileScore.click()">上传附件</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="serviceForm.multiFileDTOList"
              linkName="fileurlfull"
            />
          </div>
        </el-form-item>
        <input type="file" accept="image/*,.pdf" @change="upServiceFileScore" ref="upServiceFileScore" style="display: none;" />
      </div>
    </el-form>
    <el-form  ref="comprehensiveForm" :model="comprehensiveForm" v-if="roleList.findIndex(item => item.roleType == 3) != -1" label-width="180px" :rules="rules" label-suffix="：" inline>
      <div>
        <el-form-item label="综合服务单位评分" prop="score">
          <el-input
            v-model="comprehensiveForm.score"
            placeholder="请输入综合服务单位评分"
            @input="inputAction(comprehensiveForm, 'score')"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="综合服务单位评分附件" prop="multiFileDTOList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upComprehensiveeScore.click()">上传附件</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="comprehensiveForm.multiFileDTOList"
              linkName="fileurlfull"
            />
          </div>
        </el-form-item>
        <input type="file" accept="image/*,.pdf" @change="upComprehensiveeScore" ref="upComprehensiveeScore" style="display: none;" />
      </div>
    </el-form>
    <el-form  ref="exportForm" :model="exportForm" v-if="roleList.findIndex(item => item.roleType == 4) != -1" label-width="180px" :rules="rules" label-suffix="：" inline>
      <div>
        <el-form-item label="专家评分" prop="score">
          <el-input
            v-model="exportForm.score"
            placeholder="请输入专家评分"
            @input="inputAction(exportForm, 'score')"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="专家评分附件" prop="multiFileDTOList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upExportFileScore.click()">上传附件</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="exportForm.multiFileDTOList"
              linkName="fileurlfull"
            />
          </div>
        </el-form-item>
        <input type="file" accept="image/*,.pdf" @change="upExportFileScore" ref="upExportFileScore" style="display: none;" />
      </div>
    </el-form>
    <el-form  ref="sjForm" :model="sjForm" v-if="roleList.findIndex(item => item.roleType == 5) != -1" label-width="180px" :rules="rules" label-suffix="：" inline>
      <div>
        <el-form-item label="市局评分" prop="score">
          <el-input
            v-model="sjForm.score"
            placeholder="请输入市局评分"
            @input="inputAction(sjForm, 'score')"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="市局评分附件" prop="multiFileDTOList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upSjFileScore.click()">上传附件</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="sjForm.multiFileDTOList"
              linkName="fileurlfull"
            />
          </div>
        </el-form-item>
        <input type="file" accept="image/*,.pdf" @change="upSjFileScore" ref="upSjFileScore" style="display: none;" />
      </div>
    </el-form>
    <div class="action-style">
      <el-button :loading="isWorking" style="color:#fff; background-color: rgb(86, 188, 190)" @click="submit(1)">提交</el-button>
      <el-button :loading="isWorking" style="color:#fff; background-color: #216EEE;"  @click="submit(0)">保存</el-button>
    </div>
    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
 
  </GlobalWindow>
</template>


<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
// doneServiceList as 
import { findByDeclareId, create } from '@/api/business/declareScore'
import { findList } from '@/api/business/projectScore'
import { upload } from '@/api/system/common'
import RichEditor from '@/components/common/RichEditor.vue'

export default {
  name: 'UploadServeData',
  extends: BaseOpera,
  components: {
    GlobalWindow,
    RichEditor
  },
  data () {
    
    return {
      imageType: 'PNG/JPG/HEIF/JPEG/dds/PSD/PDT/WebP/XMP/GIF/BMP/SVG/TIFF/PDF',
      isUploading: false,
      form: {
        declareId: null
      },
      // 0企业 1县区 2服务机构 3综合服务单位 4专家 5市局,示例值(1)
      companyForm: {
        roleType: 0,
        score: '',
        multiFileDTOList: [],
      },
      xqForm: {
        roleType: 1,
        score: '',
        multiFileDTOList: [],
      },
      serviceForm: {
        roleType: 2,
        score: '',
        multiFileDTOList: [],
      },
      comprehensiveForm: {
        roleType: 3,
        score: '',
        multiFileDTOList: [],
      },
      exportForm: {
        roleType: 4,
        score: '',
        multiFileDTOList: [],
      },
      sjForm: {
        roleType: 5,
        score: '',
        multiFileDTOList: [],
      },
      roleList: [],
      // 验证规则
      rules: {
        score: [
          { required: true, message: '请输入评分', trigger: 'blur' },
        ],
        // fileScoreList: [
        //   { required: true, message: '请上传企业评分表', trigger: 'change' },
        // ],
      },
    }
  },
  created () {
    this.config({
      api: '/business/declareService',
      'field.id': 'id'
    })
  },
  
  methods: {
    open (title, declareId, projectId) {
      // debugger
      this.title = title
      this.visible = true
      this.form.declareId = declareId
      findList(projectId)
        .then(res => {
          this.roleList = res
        })
      
      
      this.$nextTick(() => {
        this.$refs.companyForm && this.$refs.companyForm.resetFields()
        this.$refs.xqForm && this.$refs.xqForm.resetFields()
        this.$refs.serviceForm && this.$refs.serviceForm.resetFields()
        this.$refs.comprehensiveForm && this.$refs.comprehensiveForm.resetFields()
        this.$refs.exportForm && this.$refs.exportForm.resetFields()
        this.$refs.sjForm && this.$refs.sjForm.resetFields()

        findByDeclareId(declareId)
          .then(res => {
            if (!res || res.length === 0) {
              for (let i = 0; i <= 5; i++) {
                res.push({
                  roleType: i,
                  score: '',
                  multiFileDTOList: [],
                })
              }
            }
            res.forEach(item => {
              if (item.roleType == 0) {
                this.companyForm = JSON.parse(JSON.stringify(item))
                this.companyForm.multiFileDTOList = this.companyForm.multiFileDTOList || []
              }
              if (item.roleType == 1) {
                this.xqForm = JSON.parse(JSON.stringify(item))
                this.xqForm.multiFileDTOList = this.xqForm.multiFileDTOList || []
              }
              if (item.roleType == 2) {
                this.serviceForm = JSON.parse(JSON.stringify(item))
                this.serviceForm.multiFileDTOList = this.serviceForm.multiFileDTOList || []
              }
              if (item.roleType == 3) {
                this.comprehensiveForm = JSON.parse(JSON.stringify(item))
                this.comprehensiveForm.multiFileDTOList = this.comprehensiveForm.multiFileDTOList || []
              }
              if (item.roleType == 4) {
                this.exportForm = JSON.parse(JSON.stringify(item))
                this.exportForm.multiFileDTOList = this.exportForm.multiFileDTOList || []
              }
              if (item.roleType == 5) {
                this.sjForm = JSON.parse(JSON.stringify(item))
                this.sjForm.multiFileDTOList = this.sjForm.multiFileDTOList || []
              }
            });
          })
        // this.$refs.companyForm && this.$refs.companyForm.clearValidate()
        // this.$refs.xqForm && this.$refs.xqForm.clearValidate()
        // this.$refs.serviceForm && this.$refs.serviceForm.clearValidate()
        // this.$refs.comprehensiveForm && this.$refs.comprehensiveForm.clearValidate()
        // this.$refs.exportForm && this.$refs.exportForm.clearValidate()
        // this.$refs.sjForm && this.$refs.sjForm.clearValidate()
        
      })
      
    },
    inputAction(form, key) {
      if( form[key]<0) {
        form[key] = ''
      } else {
        form[key] = form[key].replace(/[^\d.]/g, '')
                      .replace(/\.{2,}/g, '.')
                      .replace('.', '$#$')
                      .replace(/\./g, '')
                      .replace('$#$', '.')
                      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
                      .replace(/^\./g, '')
      }
    },
    
    // 上传企业评分表
    upCompanyFileScore(file) {
      this.uploadAction('upCompanyFileScore', this.companyForm, file)
    },
    // 上传县区评分表
    upXqFileScore(file) {
      this.uploadAction('upXqFileScore', this.xqForm, file)
    },
    // 上传服务机构评分表
    upServiceFileScore(file) {
      this.uploadAction('upServiceFileScore', this.serviceForm, file)
    },
    // 上传综合服务单位评分表
    upComprehensiveeScore(file) {
      this.uploadAction('upComprehensiveeScore', this.comprehensiveForm, file)
    },
    // 上传专家评分表
    upExportFileScore(file) {
      this.uploadAction('upExportFileScore', this.exportForm, file)
    },
    // 上传市局评分表
    upSjFileScore(file) {
      this.uploadAction('upSjFileScore', this.sjForm, file)
    },
    
    uploadAction(key, form, file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          form.multiFileDTOList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            fileurlfull: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs[key].value = null
    },

    submitForm (refName) {
      return new Promise((resolve, reject) => {
        if (!this.$refs[refName]) {
          resolve();
        }
        this.$refs[refName].validate(valid => {
          if (valid) {
            resolve();
          } else {
          // 注意此处需要传入一个new Error(''),否则会报错Expected the Promise rejection reason to be an Error
            reject(new Error('错误'));
          }
        });
      });
    },

    submit(status) {
      if (status) {
        Promise.all([
          this.submitForm('companyForm'),
          this.submitForm('xqForm'),
          this.submitForm('serviceForm'),
          this.submitForm('comprehensiveForm'),
          this.submitForm('exportForm'),
          this.submitForm('sjForm'),
        ])
          .then(() => {
            this.submitAction(status)
          })
          .catch(() => {
            this.$message.error('请填写评分')
          })
      } else {
        this.submitAction(status)
      }
    },
    submitAction(status) {
      this.isWorking = true
      let declareScore = [
        {
          ...this.form,
          ...this.companyForm,
          status
        },
        {
          ...this.form,
          ...this.xqForm,
          status
        },
        {
          ...this.form,
          ...this.serviceForm,
          status
        },
        {
          ...this.form,
          ...this.comprehensiveForm,
          status
        },
        {
          ...this.form,
          ...this.exportForm,
          status
        },
        {
          ...this.form,
          ...this.sjForm,
          status
        },
      ]
      // console.log(this.form);
      // debugger
      create(declareScore)
        .then(res => {
          this.$message.success(status?'提交成功':'保存成功')
          this.visible = false
          this.$emit('success')
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isWorking = false)
    },
  },
}

</script>


<style lang="scss" scoped>
.tip-style {
  background: rgba(33,110,238,0.1);
  border-radius: 2px;
  line-height: 24px;
  
  font-size: 16px;
  color: #333;
  padding: 8px;
  margin-bottom: 10px;
}
.tip-waring-style {
  background: rgba(33,110,238,0.1);
  border-radius: 2px;
  line-height: 24px;
  color: #EE3821;
  font-size: 16px;
  // color: #333;
  padding: 8px;
  margin-bottom: 10px;
}
.serviceList {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important
  }
  -ms-overflow-style: none;
}
.action-style {
  display:flex;
  margin-left: 120px;
  margin-bottom: 20px;
}
.arrow {
  background-color: #DFE2E8;
  color: #333333;
  height: 40px;
  line-height: 40px;
  display: flex;
  cursor: pointer;
  .arrow-foot {
    // background-color: #fff;
    border-left: 20px solid rgba($color: #fff, $alpha: 1);
    border-top: 20px solid rgba($color: #fff, $alpha: 0);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 0);
  }
  
  .arrow-body {
    height: 40px;
    width: 100%;
    min-width: 180px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 16px;
  }
  .first-last {
    min-width: 100px;
  } 
  .normal-body {
    min-width: 110px;
  } 
  .arrow-header {
    // background-color: #DFE2E8;
    border-left: 20px solid rgba($color: #fff, $alpha: 0);
    border-top: 20px solid rgba($color: #fff, $alpha: 1);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 1);
  }
}
.arrow-select {
  background-color: #216EEE;
  color: #fff;
}

.data-list {
  margin-top: 20px;
  .data-item {
    border-radius: 2px;
    // background-color: #F5F6F8;
    height: 36px;
    display: flex;
    // padding: 11px 20px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #216EEE;
    cursor: pointer;
    text-decoration: underline;
    line-height: 13px;
    margin-right: 16px;
    i {
      box-sizing: border-box;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      // background-color: #BFC3CB;
      color: #216EEE;
      border-radius: 50%;
      // padding: 1.5px;
      cursor: pointer;
      &:hover {
        color: #ff0000;
      }
    }
  }
}
::v-deep .ql-container {
  height: 200px !important;
  width: 850px;
}
::v-deep .ql-toolbar {
  // height: 200px !important;
  width: 850px;
}
::v-deep .el-form-item {
  display: flex;
}
::v-deep .el-form-item__content {
  flex: 1;
}
::v-deep .swiper-slide {
  width: 230px !important;
}
</style>
